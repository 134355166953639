import React from 'react';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const IndexPage = () => (
  <Layout locale="pt-BR">
    <SEO
      lang="pt-BR"
      title="Da domesticidade cotidiana"
      description="A intimidade decorrente de certa domesticidade acaba sendo uma das vias em que o desconforto e o desamparo
        tornam-se presentes na existência humana."
    />
    <article className="article">
      <h1>Da domesticidade cotidiana</h1>
      <p className="author">Ivair Reinaldim</p>
      <p>
        É comum associarmos o universo doméstico àquilo que nos é familiar e a
        certa noção de conforto e aconchego. Mas sabemos também que justamente
        no âmbito da casa, no mundo privado, ocorrem muitas das situações de
        violência física e psicológica a que inúmeras pessoas são submetidas em
        suas vidas. A intimidade decorrente de certa domesticidade – e cabe aqui
        pensarmos no sentido de domesticação e nos processos inerentes a essa
        condição – acaba sendo uma das vias em que o desconforto e o desamparo
        tornam-se presentes na existência humana.
      </p>

      <p>
        A série <strong>Doméstico Perverso</strong> da artista Katharina Welper
        insere-se na investigação plástico-visual desse universo de questões. A
        partir de um conjunto de bordados de temas florais, decorrentes de
        padrões do leste europeu, realizados pela artista sobre lençóis e outras
        peças pertencentes a um antigo enxoval de uma enfermeira alemã (grafados
        com as iniciais E.B.), os trabalhos que constituem essa série parecem
        reconfigurar aspectos já bastante decodificados de obras contemporâneas
        que se alicerçam na prática do bordado: o universo feminino, a
        delicadeza e a ornamentação dos motivos, a domesticidade dos trabalhos
        manuais. Não que essas obras não possam ser compreendidas tendo como
        base essa chave de interpretação; no entanto, não se deixam limitar a
        ela.
      </p>

      <p>
        Nesse sentido, a ideia de perversão presente no título da série cumpre
        duas funções essenciais. Por um lado, no plano discursivo, a ideia de
        perversão, mais que mera alusão a um conceito, é também ato. Não só
        representa, mas confere um senso político a esse trabalhos. Ou seja,
        referem-se de modo metafórico às pequenas e grandes perversões
        praticadas no âmbito da domesticidade, das situações vividas e nem
        sempre partilhadas para além da esfera do privado – seja este privado a
        literalidade da casa, do âmbito familiar mais imediato ou mesmo da
        subjetividade específica de cada pessoa. Essa domesticidade, então,
        torna-se lugar do segredo, do oculto, do não dito, do não familiar.
      </p>

      <p>
        Por outro lado, a perversão visa corromper, alterar uma condição
        preexistente e ancorada em certa condição social de normalidade
        consensual. Ela representa o desvio às normas. Em termos práticos, o ato
        de bordar de Katharina Welper subverte certos mecanismos inerentes aos
        modos de fazer da técnica, ao se realizar não mais sobre uma superfície
        lisa. Embora a frontalidade do trabalho reforce a linearidade dos temas
        bordados pela artista, em um olhar mais atento sobre o tecido, é
        possível apreender as dobras e amassados, a reconfiguração de sua
        forma-base, como se a ação da artista quisesse evidenciar uma nova
        visualidade, sem apagar as marcas e histórias presentes naquele suporte.
        A presença de outro bordado, mais antigo, na mesma cor dos tecidos,
        reforça a diferenciação não só dos aspectos estruturais do novo bordado,
        mas também dos modos de sua execução.
      </p>

      <p>
        Interessa-nos aqui pensar como o ato da artista relaciona-se intimamente
        com o material que elege como suporte. Os tecidos costurados e
        preparados para constituir o enxoval (lençóis, fronhas, toalhas, etc.),
        utilizados e desgastados durante seu uso ou mesmo pela passagem do
        tempo, marcados por memórias não conhecidas, tinham sua condição de
        existência ligada ao doméstico, ao privado, mas agora, ressignificados
        como trabalhos da artista, têm sua aparição subvertida, a partir de nova
        existência pública. A delicadeza e a destreza no bordar sugerem o
        encobrimento dessas dobras e marcas do tempo. Porém, mais do que
        esconder, acentuam a contradição inerente a esse processo.
      </p>
      <p>
        Cabe aqui (re)pensar a domesticidade tanto desses tecidos ligados ao
        âmbito da casa quanto da prática do bordado – embora hoje haja uma
        condição de produção industrial que coabita com a persistência do gesto
        manual. O ato enquanto ação do fazer reforça o ato enquanto sentido de
        “dar a ver” o processo de tornar doméstico (domesticar). Esses trabalhos
        evidenciam a não possibilidade das aparências eliminarem as camadas e
        processos subjacentes, os conflitos e contradições que daí decorrem. Há,
        enfim, algo de comum – no sentido de comunidade – em sua singularidade.
      </p>
    </article>
  </Layout>
);

export default IndexPage;
